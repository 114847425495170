<!--  -->
<template>
  <div class='xuefen round bj-col-white'>
    <!-- <div class="nav">
      <div class="btn">
        <el-button round size="small" plain type="success" class="self-button--success" style="width: 100px" v-print="'#printTest'">打印</el-button>
        <el-button round size="small" type="success" class="self-button--success" style="width: 100px;margin-left: 20px" @click="handleSave">导出</el-button>
      </div>
    </div> -->

    <div v-if="hide" class="err"><span>学分卡信息获取失败</span></div>

    <div class="imgUrl">
      <div class="content" id="printTest" v-if="show">
        <div class="title">广东省执业药师继续教育证明</div>
  
        <div class="time">(<span>{{year}}</span>年)</div>
  
        <div class="msg">
          执业药师<span style="font-weight: bold;">{{info.user_name}}</span>（身份证号：<span>{{info.card}}</span>）,参加我单位组织的<span>{{year}}</span>年度广东省执业药师继续教育公需科目学习，经考核成绩合格，授予执业药师继续教育公需科目 <span style="font-weight: bold;">{{info.xuefen}}</span> 学分、计 <span style="font-weight: bold;">{{info.xueshi}}</span> 学时。
        </div>
  
        <img src="../../assets/img/shuiyin.png" alt=""/>
  
        <div class="hint">
          <div>本学分证明在全国范围内有效。</div>
          <div>特此证明。</div>
        </div>
  
        <div class="other">
          <div class="code">
            <div ref="qrcode"></div>
            <!-- <div>
              扫描查验真伪  
              <span>(防伪二维码)</span>
            </div> -->
          </div>
          <div class="zhang">
            <img src="../../assets/img/zz.png" alt="" oncontextmenu="return false;" ondragstart="return false;">
            <div>长沙市湘源医药食品职业培训学校</div>
            <div>
              <span>{{info.year}}</span>年<span>{{info.month}}</span>月<span>{{info.day}}</span>日
            </div>
          </div>
        </div>
  
        <div style="text-align: center">学分查询登录广东省执业药师管理系统（https://zyys.gdfda.cn/）</div>
      </div>
      <div class="img">
        <img :src="imgUrl" alt="">
      </div>
      <div class="content" id="export" v-if="show">
        <div>
          <div class="title">广东省执业药师继续教育证明</div>
  
          <div class="time">(<span>{{year}}</span>年)</div>
  
          <div class="msg">
            执业药师<span style="font-weight: bold;">{{info.user_name}}</span>（身份证号：<span>{{info.card}}</span>）,参加我单位组织的<span>{{year}}</span>年度广东省执业药师继续教育公需科目学习，经考核成绩合格，授予执业药师继续教育公需科目 <span style="font-weight: bold;">{{info.xuefen}}</span> 学分、计 <span style="font-weight: bold;">{{info.xueshi}}</span> 学时。
          </div>
  
          <img src="../../assets/img/shuiyin.png" alt=""/>
  
          <div class="hint">
            <div>本学分证明在全国范围内有效。</div>
            <div>特此证明。</div>
          </div>
  
          <div class="other">
            <div class="code">
              <div ref="qrcode2"></div>
              <!-- <div>
                扫描查验真伪  
                <span>(防伪二维码)</span>
              </div> -->
            </div>
            <div class="zhang">
              <img src="../../assets/img/zz.png" alt="" oncontextmenu="return false;" ondragstart="return false;">
              <div>长沙市湘源医药食品职业培训学校</div>
              <div>
                <span>{{info.year}}</span>年<span>{{info.month}}</span>月<span>{{info.day}}</span>日
              </div>
            </div>
          </div>
  
          <div style="text-align: center">学分查询登录广东省执业药师管理系统（https://zyys.gdfda.cn/）</div>
        </div>
      </div>
    </div>

    <div class="logo">
      <img src="../../assets/img/logo.jpg" alt="">
      <div>
        <h2>湘源医药食品培训</h2>
        <span>Xiangyuan Pharmaceutical and Food Training</span>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'
import http from '../../utils/request'

export default {
  components: {},
  data() {
    return {
      info: {},
      show: false,
      year: '',
      hide: false,
      imgUrl: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    // document.onkeydown = document.onkeyup = document.onkeypress = function(event) { 
    //   var e = event || window.event || arguments.callee.caller.arguments[0]; 
    //   if (e && e.keyCode == 123) { 
    //     e.returnValue = false; 
    //     return (false); 
    //   } 
    // }
    // document.oncontextmenu = new Function("return false;");
  },
  mounted() {
    if (window.location.href && window.location.href.indexOf('jmstr') != -1) {
      let search = window.location.href.split('?')[1]
      if (search.indexOf('&') != -1) {
        search = search.split('&')
        for (let i in search) {
          if (search[i].indexOf('jmstr') != -1) {
            search = search[i]
          }
        }
      }
      if(search.indexOf('jmstr') != -1) {
        let options = {
          jmstr: search.split("=")[1],
          type: '2'
        }
        this.getCredit(options)
      }
    }
  },
  beforeDestroy () {
    // document.onkeydown = document.onkeyup = document.onkeypress = () => {}
    // document.oncontextmenu = () => {}
  },
  //方法集合
  methods: {
    preventDefaultListener (e) {
      e.preventDefault()
    },
    getCredit (options) {
      http({
        url: "Home/ApiRenew/jmstr_xfzm",
        method: "post",
        data: options
      }).then(res => {
        if (res.data.code == 1) {
          this.show = true
          this.info = res.data.data
          this.year = res.data.data.year
          if (this.info.ks_shtime.length > 0) {
            let time = this.info.ks_shtime.split('年')[1]
            this.info.year = this.info.ks_shtime.split('年')[0]
            let month = time.split('月')[0]
            time = time.split('月')[1]
            let day = time.split('日')[0]
            this.info.month = month
            this.info.day = day
          }
          if (this.info.ewm) {
            this.$nextTick(() => {
              var qrcode = new QRCode(this.$refs.qrcode, {
                width: 100,
                height: 100,
              });
              qrcode.makeCode(this.info.ewm);
              var qrcode2 = new QRCode(this.$refs.qrcode2, {
                width: 100,
                height: 100,
              });
              qrcode2.makeCode(this.info.ewm);
              document.getElementById('export').style.height = 'auto'
              new html2canvas(document.getElementById('export')).then(canvas => {
                document.getElementById('export').style.height = '0'
                this.imgUrl = canvas.toDataURL()
              })
            })
          }
        } else {
          this.hide = true
          this.$message.error("学分卡信息获取失败")
        }
      })
    },
    handleSave () {
      document.getElementById('export').style.height = 'auto'
      new html2canvas(document.getElementById('export')).then(canvas => {
        this.savePicture(canvas.toDataURL())
      })
    },
    savePicture(Url) {
      var blob = new Blob([''], { type: 'application/octet-stream' });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = Url;
      a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, "$2").split("?")[0];
      var e = document.createEvent('MouseEvents');
      e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
      URL.revokeObjectURL(url);
      document.getElementById('export').style.height = '0px'
    },
  },
}
</script>

<style lang='less' scoped>
.xuefen {
  padding: 0 40px;
  position: relative;
  height: 100%;
  overflow-y: auto;
  .nav {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .year {
      display: flex;
      align-items: center;
      > span {
        margin-right: 15px;
      }
    }
  }
  .err {
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
    span {
      font-size: 28px;
    }
  }
  .imgUrl {
    position: relative;
    margin: 0 auto;
    > .img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      > img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: block;
      }
    }
  }
  .content {
    width: 1000px;
    margin: 0 auto;
    border: 1px solid transparent;
    border-top-width: 250px;
    border-left-width:  90px;
    border-right-width: 90px;
    border-bottom-width: 240px;
    border-image: url("../../assets/img/weituoshu.png") 250 90 stretch;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    background-image: url('../../assets/img/xuefen2.png');
    background-size: 100% auto;
    background-position: left bottom;
    position: relative;
    > div {
      width: 100%;
    }
    > img {
      width: 30%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-35deg);
      opacity: 0.2;
    }
    div, span {
      font-size: 20px;
    }
    span {
      color: #000;
    }
    .title {
      text-align: center;
      font-size: 30px;
    }
    .time {
      text-align: center;
      margin-top: 10px;
      font-size: 24px;
      span {
        font-size: 24px;
        color: red;
      }
    }
    .msg {
      text-indent: 35px;     
      margin-top: 40px; 
      span {
        text-indent: 0;     
        display: inline-block;
        padding: 0 15px;
        border-bottom: 1px solid #000;
      }
    }
    .hint {
      margin: 10px 0;
      > div {
        text-indent: 35px;
      }
    }
    .other {
      margin: 40px 0;
      display: flex;
      justify-content: space-between;
      width: 90%;
      .code {
        display: flex;
        > div:last-child {
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;
        }
      }
      .zhang {
        position: relative;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        img {
          width: 140px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        span {
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
  #export {
    width: 1400px;
    height: 0;
    margin: 0 auto;
    box-sizing: content-box;
    background-image: url('../../assets/img/weituoshu.png');
    background-size: 100% 100%;
    background-position: top left;
    border: 0;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    > div {
      width: 1000px;
      height: 1000px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      > div {
        width: 100%;
      }
      .title {
        margin-top: 15%;
      }
      .time {
        margin-bottom: 60px;
      }
      .other {
        margin-bottom: 180px;
      }
      > img {
        width: 30%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-35deg);
        opacity: 0.2;
      }
    }
  }
  .logo {
    margin: 20px auto;
    width: fit-content;
    display: flex;
    align-items: center;
    opacity: 0.3;
    img {
      width: 50px;
    }
    > div {
      margin-left: 10px;
      span {
        font-size: 10px;
      }
    }
  }
}
@media print {
  .content {
    width: 1000px;
    margin: 0 auto;
    width: 1000px;
    margin: 0 auto;
    border: 1px solid transparent;
    border-top-width: 250px;
    border-left-width:  90px;
    border-right-width: 90px;
    border-bottom-width: 240px;
    border-image: url("../../assets/img/weituoshu.png") 250 90 stretch;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    background-image: url('../../assets/img/xuefen2.png');
    background-size: 100% auto;
    background-position: left bottom;
    position: relative;
    zoom: 23.8%;
    > div {
      width: 100%;
    }
    > img {
      width: 30%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-35deg);
      opacity: 0.2;
    }
    div, span {
      font-size: 20px;
    }
    span {
      color: #000;
    }
    .title {
      text-align: center;
      font-size: 30px;
    }
    .time {
      text-align: center;
      margin-top: 10px;
      font-size: 24px;
      span {
        font-size: 24px;
        color: red;
      }
    }
    .msg {
      text-indent: 35px;     
      margin-top: 40px; 
      span {
        text-indent: 0;     
        display: inline-block;
        padding: 0 15px;
        border-bottom: 1px solid #000;
      }
    }
    .hint {
      margin: 10px 0;
      > div {
        text-indent: 35px;
      }
    }
    .other {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      width: 90%;
      .code {
        display: flex;
        > div:last-child {
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;
        }
      }
      .zhang {
        position: relative;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        img {
          width: 100px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        span {
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
}
</style>