<!--  -->
<template>
  <div class='xuefen round bj-col-white'>
    <div class="nav">
      <div class="year">
        <span>选择年份</span>
        <el-select v-model="year" placeholder="请选择" @change="onChange" class="picker">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="btn">
        <el-button round size="small" plain type="success" class="self-button--success" style="width: 100px" v-print="'#printTest'">打印</el-button>
        <el-button round size="small" type="success" class="self-button--success" style="width: 100px;margin-left: 20px" @click="handleSave">导出</el-button>
      </div>
    </div>

    <div class="imgUrl">
      <div class="content" id="printTest" v-if="show">
        <div class="title">广东省执业药师继续教育证明</div>
  
        <div class="time">(<span>{{year}}</span>年)</div>
  
        <div class="msg">
          执业药师<span style="font-weight: bold;">{{info.user_name}}</span>（身份证号：<span>{{info.card}}</span>）,参加我单位组织的“广东省执业药师继续教育课程”学习，经考核，成绩合格，授予执业药师继续教育 <span>{{info.xuefen}}</span> 学分、计 <span>{{info.xueshi}}</span> 学时。
        </div>
  
        <div class="hint">
          <div>本学分证明在全国范围内有效。</div>
          <div>特此证明。</div>
        </div>
  
        <div class="head">
          <span>{{year}}</span>年执业药师继续教育专业科目<span>网授培训</span>课程学分/学时授予表
        </div>
  
        <div class="list">
          <div>
            <span>序号</span>
            <div>课程</div>
          </div>
          <img src="../../assets/img/shuiyin.png" alt="">
          <div v-for="(item, index) in info.kecheng" :key="index">
            <span>{{index+1}}</span>
            <div>{{item}}</div>
          </div>
          <div v-for="(item, index) in list" :key="index+'10'">
            <span></span>
            <div></div>
          </div>
          <div>
            <div>学分总计</div>
            <div style="font-weight: bold;">20</div>
            <div>学时总计</div>
            <div style="font-weight: bold;">60</div>
          </div>
        </div>
  
        <div class="other">
          <div class="code">
            <div ref="qrcode"></div>
            <div>
              扫描查验真伪  
              <span>(防伪二维码)</span>
            </div>
          </div>
          <div class="zhang">
            <img src="../../assets/img/zz.png" alt="" oncontextmenu="return false;" ondragstart="return false;">
            <div>长沙市湘源医药食品职业培训学校</div>
            <div>
              <span>{{info.year}}</span>年<span>{{info.month}}</span>月<span>{{info.day}}</span>日
            </div>
          </div>
        </div>
  
        <div style="text-align: center">学分查询登录广东省执业药师管理系统（https://zyys.gdfda.cn/）</div>
      </div>
      <img :src="imgUrl" alt="">
  
      <div class="content" id="export" v-if="show">
        <div>
          <div class="title">广东省执业药师继续教育证明</div>
  
          <div class="time">(<span>{{year}}</span>年)</div>
  
          <div class="msg">
            执业药师<span style="font-weight: bold;">{{info.user_name}}</span>（身份证号：<span>{{info.card}}</span>）,参加我单位组织的“广东省执业药师继续教育课程”学习，经考核，成绩合格，授予执业药师继续教育 <span>{{info.xuefen}}</span> 学分、计 <span>{{info.xueshi}}</span> 学时。
          </div>
  
          <div class="hint">
            <div>本学分证明在全国范围内有效。</div>
            <div>特此证明。</div>
          </div>
  
          <div class="head">
            <span>{{year}}</span>年执业药师继续教育专业科目<span>网授培训</span>课程学分/学时授予表
          </div>
  
          <div class="list">
            <div>
              <span>序号</span>
              <div>课程</div>
            </div>
            <img src="../../assets/img/shuiyin.png" alt="">
            <div v-for="(item, index) in info.kecheng" :key="index">
              <span>{{index+1}}</span>
              <div>{{item}}</div>
            </div>
            <div v-for="(item, index) in list" :key="index+'10'">
              <span></span>
              <div></div>
            </div>
            <div>
              <div>学分总计</div>
              <div style="font-weight: bold;">20</div>
              <div>学时总计</div>
              <div style="font-weight: bold;">60</div>
            </div>
          </div>
  
          <div class="other">
            <div class="code">
              <div ref="qrcode2"></div>
              <div>
                扫描查验真伪  
                <span>(防伪二维码)</span>
              </div>
            </div>
            <div class="zhang">
              <img src="../../assets/img/zz.png" alt="" oncontextmenu="return false;" ondragstart="return false;">
              <div>长沙市湘源医药食品职业培训学校</div>
              <div>
                <span>{{info.year}}</span>年<span>{{info.month}}</span>月<span>{{info.day}}</span>日
              </div>
            </div>
          </div>
  
          <div style="text-align: center">学分查询登录广东省执业药师管理系统（https://zyys.gdfda.cn/）</div>
        </div>
      </div>
    </div>

    <div class="logo">
      <img src="../../assets/img/logo.jpg" alt="">
      <div>
        <h2>湘源医药食品职业培训学校</h2>
        <span>Xiangyuan Pharmaceutical and Food Training</span>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'
import Api from '../../api/my'

export default {
  components: {},
  data() {
    return {
      info: {},
      year: '',
      options: [],
      list: 0,
      show: false,
      imgUrl: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    this.options = userInfo.years.map(item => {
      return {
        label: item,
        value: item
      }
    })
    this.year = localStorage.getItem("year")
    // document.onkeydown = document.onkeyup = document.onkeypress = function(event) { 
    //   var e = event || window.event || arguments.callee.caller.arguments[0]; 
    //   if (e && e.keyCode == 123) { 
    //     e.returnValue = false; 
    //     return (false); 
    //   } 
    // }
    // document.oncontextmenu = new Function("return false;");
  },
  mounted() {
    this.getCredit()
  },
  beforeDestroy () {
    // document.onkeydown = document.onkeyup = document.onkeypress = () => {}
    // document.oncontextmenu = () => {}
  },
  //方法集合
  methods: {
    preventDefaultListener (e) {
      e.preventDefault()
    },
    getCredit () {
      Api.getCredit({
        year: this.year
      }).then(res => {
        if (res.status == 200) {
          this.show = true
          this.info = res.data
          if (this.info.kecheng.length < 15) {
            this.list = 15 - this.info.kecheng.length
          }
          if (this.info.ks_shtime.length > 0) {
            let time = this.info.ks_shtime.split('年')[1]
            this.info.year = this.info.ks_shtime.split('年')[0]
            let month = time.split('月')[0]
            time = time.split('月')[1]
            let day = time.split('日')[0]
            this.info.month = month
            this.info.day = day
          }
          if (this.info.ewm) {
            this.$nextTick(() => {
              var qrcode = new QRCode(this.$refs.qrcode, {
                width: 120,
                height: 120,
              });
              qrcode.makeCode(this.info.ewm);
              var qrcode2 = new QRCode(this.$refs.qrcode2, {
                width: 120,
                height: 120,
              });
              qrcode2.makeCode(this.info.ewm);
              document.getElementById('export').style.height = 'auto'
              new html2canvas(document.getElementById('export')).then(canvas => {
                document.getElementById('export').style.height = '0'
                this.imgUrl = canvas.toDataURL()
              })
            })
          }
        } else {
          this.$message.error("学分卡信息获取失败")
        }
      })
    },
    onChange () {
      this.show = false
      localStorage.setItem("year", this.year)
      Api.getUserInfo().then(res => {
        if (res.status == 200) {
          let info = res.data
          localStorage.setItem('userInfo', JSON.stringify(info))
          if (info.kaohe != 3) {
            this.$router.back()
          } else {
            this.getCredit()
          }
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
    handleSave () {
      document.getElementById('export').style.height = 'auto'
      new html2canvas(document.getElementById('export')).then(canvas => {
        this.savePicture(canvas.toDataURL())
      })
    },
    savePicture(Url) {
      var blob = new Blob([''], { type: 'application/octet-stream' });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = Url;
      a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, "$2").split("?")[0];
      var e = document.createEvent('MouseEvents');
      e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
      URL.revokeObjectURL(url);
      document.getElementById('export').style.height = '0px'
    },
  },
}
</script>

<style lang='less' scoped>
.xuefen {
  padding: 0 40px;
  position: relative;
  .nav {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .year {
      display: flex;
      align-items: center;
      > span {
        margin-right: 15px;
      }
    }

  }
  .imgUrl {
    position: relative;
    margin: 0 auto;
    > img {
      width: 1180px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .content {
    width: 1000px;
    margin: 0 auto;
    border: 1px solid transparent;
    border-top-width: 250px;
    border-left-width:  90px;
    border-right-width: 90px;
    border-bottom-width: 240px;
    border-image: url("../../assets/img/weituoshu.png") 250 90 stretch;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    background-image: url('../../assets/img/xuefen2.png');
    background-size: 100% auto;
    background-position: left bottom;
    > div {
      width: 100%;
    }
    div, span {
      font-size: 20px;
    }
    span {
      color: #000;
    }
    .title {
      text-align: center;
      font-size: 34px;
    }
    .time {
      text-align: center;
      margin-top: 10px;
      font-size: 24px;
      span {
        font-size: 24px;
        color: red;
      }
    }
    .msg {
      text-indent: 35px;     
      margin-top: 40px; 
      span {
        text-indent: 0;     
        display: inline-block;
        padding: 0 15px;
        border-bottom: 1px solid #000;
      }
    }
    .hint {
      margin: 10px 0;
      > div {
        text-indent: 35px;
      }
    }
    .head {
      text-align: center;
    }
    .list {
      width: 90%;
      margin-top: 20px;
      border: 1px solid #000;
      position: relative;
      > div:first-child {
        div {
          color: #000;
        }
      }
      > div {
        display: flex;
        border-bottom: 1px solid #000;
        height: 42px;
        line-height: 42px;
        span {
          width: 80px;
          text-align: center;
          color: #000;
          border-right: 1px solid #000;
        }
        div {
          flex: 1;
          text-align: center;
          color: #000;
        }
      }
      > div:last-child {
        border: none;
        height: 48px;
        line-height: 48px;
        > div {
          width: 25%;
          border-right: 1px solid #000;
          color: #000;
          font-size: 24px;
        }
        > div:last-child {
          border-right: 0;
        }
      }
      img {
        width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-35deg);
        opacity: 0.2;
      }
    }
    .other {
      margin: 40px 0;
      display: flex;
      justify-content: space-between;
      width: 90%;
      .code {
        display: flex;
        > div:last-child {
          height: 120px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;
        }
      }
      .zhang {
        position: relative;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        img {
          width: 140px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        span {
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
  #export {
    width: 1400px;
    height: 0;
    margin: 0 auto;
    box-sizing: content-box;
    background-image: url('../../assets/img/weituoshu.png');
    background-size: 100% 100%;
    background-position: top left;
    border: 0;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    > div {
      width: 1000px;
      margin: 0 auto 32%;
      min-height: 1000px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-top: 22%;
      }
      .time {
        margin-bottom: 30px;
      }
      > div {
        width: 100%;
      }
    }
  }
  .logo {
    margin: 20px auto;
    width: fit-content;
    display: flex;
    align-items: center;
    opacity: 0.3;
    img {
      width: 50px;
    }
    > div {
      margin-left: 10px;
      span {
        font-size: 10px;
      }
    }
  }
}
@media print {
  .content {
    margin: 0 auto;
    width: 1000px;
    margin: 0 auto;
    border: 1px solid transparent;
    border-top-width: 250px;
    border-left-width:  90px;
    border-right-width: 90px;
    border-bottom-width: 240px;
    border-image: url("../../assets/img/weituoshu.png") 250 90 stretch;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F6F6;
    background-image: url('../../assets/img/xuefen2.png');
    background-size: 100% auto;
    background-position: left bottom;
    zoom: 23.8%;
    > div {
      width: 100%;
    }
    div, span {
      font-size: 20px;
    }
    span {
      color: #000;
    }
    .title {
      text-align: center;
      font-size: 30px;
    }
    .time {
      text-align: center;
      margin-top: 10px;
      font-size: 24px;
      span {
        font-size: 24px;
        color: red;
      }
    }
    .msg {
      text-indent: 35px;     
      margin-top: 40px; 
      span {
        text-indent: 0;     
        display: inline-block;
        padding: 0 15px;
        border-bottom: 1px solid #000;
      }
    }
    .hint {
      margin: 10px 0;
      > div {
        text-indent: 35px;
      }
    }
    .head {
      text-align: center;
    }
    .list {
      width: 90%;
      margin-top: 20px;
      border: 1px solid #000;
      position: relative;
      > div:first-child {
        div {
          color: #000;
        }
      }
      > div {
        display: flex;
        border-bottom: 1px solid #000;
        height: 42px;
        line-height: 42px;
        span {
          width: 80px;
          text-align: center;
          color: #000;
          border-right: 1px solid #000;
        }
        div {
          flex: 1;
          text-align: center;
          color: #000;
        }
      }
      > div:last-child {
        border: none;
        height: 48px;
        line-height: 48px;
        > div {
          width: 25%;
          border-right: 1px solid #000;
          color: #000;
          font-size: 24px;
        }
        > div:last-child {
          border-right: 0;
        }
      }
      img {
        width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-35deg);
        opacity: 0.2;
      }
    }
    .other {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      width: 90%;
      .code {
        display: flex;
        > div:last-child {
          height: 120px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;
        }
      }
      .zhang {
        position: relative;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        img {
          width: 100px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        span {
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
}
</style>