<!--  -->
<template>
  <div class='main'>
    <!-- <div class="nav">
      <div class="consult round bj-col-white" v-if="consult">
        <h2>通知公告</h2>
        <div class="more" @click="handleMore('ConsultList')">查看更多</div>
        <consult-view @hide="changeHide"></consult-view>
      </div>

      <div class="introduce">
        <div class="bj-col-white round practice">
          <h2>执业药师模块</h2>
          <div>
            <template v-for="(item, index) in links.zyys">
              <img :src="item.name" alt="" @click="handleLink(item.src, index)" :key="index"/>
            </template>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="bj-col-white round flow">
      <h2>继续教育专业科目学习流程</h2>
      <div>
        <div v-for="(item, index) in study_step" :key="index" @click="handleLink(item.src)">
          <div :style="item.style"></div>
          <span>{{item.title}}</span>
        </div>
      </div>
    </div> -->

    <!-- <div class="flows">
      <h2>继续教育学习流程</h2>
      <div>
        <div @click="handleLink('https://video.xyyysp.com/xtbm01.pdf')">
          <div class="xuhao" style="border-color: #205ABF;color: #205ABF">01</div>
          <div class="line" style="background-color: #205ABF"></div>
          <span>广东执业药师管理系统报名</span>
        </div>
        <div @click="handleLink('https://video.xyyysp.com/xzjg02.pdf')">
          <div class="xuhao" style="border-color: #4CC08C;color: #4CC08C">02</div>
          <div class="line" style="background-color: #4CC08C"></div>
          <span>选择湘源医药食品学校</span>
        </div>
        <div>
          <div class="xuhao" style="border-color: #006CE4;color: #006CE4">03</div>
          <div class="line" style="background-color: #006CE4"></div>
          <span>上传委托书</span>
        </div>
        <div @click="handleLink('https://video.xyyysp.com/xzkc03.pdf')">
          <div class="xuhao" style="border-color: #FF8C21;color: #FF8C21">03</div>
          <div class="line" style="background-color: #FF8C21"></div>
          <span>选择课程</span>
        </div>
        <div @click="handleLink('https://video.xyyysp.com/qrjf04.pdf')">
          <div class="xuhao" style="border-color: #00BEAF;color: #00BEAF">04</div>
          <div class="line" style="background-color: #00BEAF"></div>
          <span>确认缴费</span>
        </div>
        <div @click="handleLink('https://video.xyyysp.com/xxks05.pdf')">
          <div class="xuhao" style="border-color: #F9BC15;color: #F9BC15">05</div>
          <div class="line" style="background-color: #F9BC15"></div>
          <span>学习考试</span>
        </div>
        <div @click="handleLink('https://video.xyyysp.com/xfdy06.pdf')">
          <div class="xuhao" style="border-color: #4CC08B;color: #4CC08B">06</div>
          <div class="line" style="background-color: #4CC08B"></div>
          <span>学分打印</span>
        </div>
      </div>
    </div> -->

    <div class="continue round bj-col-white">
      <h2>继续教育</h2>
      <h2>Education</h2>
      <div>
        <div @click="handleLink('https://zyys.gdfda.cn/')">
          <span>广东省执业药师管理系统</span>
          <img src="../assets/img/rk_gl.png" alt="">
          <div>报名入口</div>
        </div>
        <div @click="handleLink('zykm')">
          <span>专业科目</span>
          <img src="../assets/img/rk_zy.png" alt="">
          <div>学习入口</div>
        </div>
        <div @click="handleLink('gxkm')">
          <span>公需科目<br>(免费)</span>
          <img src="../assets/img/rk_gx.png" alt="">
          <div>学习入口</div>
        </div>
        <div @click="handleLink('xfcx')">
          <span>学分查询</span>
          <img src="../assets/img/rk_xf.png" alt="">
          <div>查询入口</div>
        </div>
        <!-- <div @click="handleLink('https://zyys.gdfda.cn/credits/index')">
          <span>学分查询</span>
          <img src="../assets/img/rk_xf.png" alt="">
          <div>查询入口</div>
        </div> -->
        <div @click="handleLink('https://www.google.cn/chrome/')" class="upload">
          <span>电脑学习</span>
          <img src="../assets/img/chrom.png" style="width: 110px" alt="">
          <p>请点击下载<br><span>谷歌浏览器</span>学习<br>否则无学习记录</p>
        </div>
      </div>
    </div>

    <div class="new-flows">
      <h2>学习流程</h2>
      <h2>Process</h2>
      <div>
        <div style="padding-top: 30px" @click="handleLink('https://video.xyyysp.com/xtbm01.pdf')">
          <span>01</span>
          <div>广东执业药师管理<br>系统报名</div>
        </div>
        <div style="position: relative; bottom: 20px;" @click="handleLink('https://video.xyyysp.com/xzjg02.pdf')">
          <div class="bottom">湘源医药食品<br>学校</div>
          <span>02</span>
        </div>
        <div style="padding-bottom: 30px" @click="handleLink('https://video.xyyysp.com/xzkc03.pdf')">
          <span>03</span>
          <div>选择课程</div>
        </div>
        <div style="position: relative; bottom: 30px;" @click="handleLink('https://video.xyyysp.com/qrjf04.pdf')">
          <div class="bottom">确认缴费</div>
          <span>04</span>
        </div>
        <div style="padding-top: 80px" @click="handleLink('https://video.xyyysp.com/xxks05.pdf')">
          <span>05</span>
          <div>学习考试</div>
        </div>
        <div style="padding-top: 10px" @click="handleLink('https://video.xyyysp.com/xfdy06.pdf')">
          <span>06</span>
          <div>学分打印</div>
        </div>
      </div>
    </div>

    <div class="news round bj-col-white" v-if="consult">
      <h2>新闻动态</h2>
      <h2>News</h2>
      <div class="more" @click="handleMore('ConsultList')">查看更多</div>
      <news-view @hide="changeHide"></news-view>
    </div>

    <div class="teacher-list round bj-col-white" v-if="teacherArr.length > 0">
      <h2>公益课程</h2>
      <h2>Expert</h2>
      <div class="msg">
        <div class="head" v-if="!videoShow">
          <img :src="teacherArr[teacherIdx].img" alt="">
          <div>
            <span>免费学习</span>
            <div @click="videoShow = true">
              <div></div>
            </div>
          </div>
        </div>
        <video v-else :src="teacherArr[teacherIdx].video" ref="video" autoplay controls></video>
        <div class="content">
          <div class="name">
            <span>{{teacherArr[teacherIdx].name}}</span>
            <div>{{teacherArr[teacherIdx].title}}</div>
          </div>
          <div class="detail">{{teacherArr[teacherIdx].content}}</div>
          <div class="task">主讲课题：{{teacherArr[teacherIdx].kecheng}}</div>
        </div>
      </div>
      <div class="arr">
        <div :class="teacherIdx == index && 'checked'" v-for="(item, index) in teacherArr" :key="index" @click="videoShow = false, teacherIdx = index">
          <div class="head">
            <img :src="item.img" alt="">
          </div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
    </div>
    
    <div class="tixi round bj-col-white">
      <h2>课程体系</h2>
      <h2>System</h2>
      <div class="table">
        <div class="thead">
          <div class="xuhao"></div>
          <div class="name">课程名称</div>
          <div class="user">主讲人</div>
          <div class="title">职称</div>
        </div>
        <div class="tlist">
          <div class="tbody" v-for="(item, index) in tixiArr" :key="index">
            <div class="xuhao">{{index+1}}</div>
            <div class="name">{{item.mingcheng}}</div>
            <div class="user">{{item.zjr}}</div>
            <div class="title">{{item.zhiwu}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="course round bj-col-white" @click="$router.push({name: 'Tixi'})">
      <h2>课程构造</h2>
      <h2>Structure</h2>
      <img src="../assets/img/kecheng.png" alt="">
    </div>

    <div class="teacher round bj-col-white">
      <h2>名师风采</h2>
      <div class="more" @click="handleMore('Teacher')">查看更多</div>
      <div class="teacher-arr" ref="homeNews">
        <div v-for="(item, index) in teacher_arr" :key="index" @click="handleTeacher(item.id)">
          <img :src="item.img" alt="">
          <span>{{item.name}}</span>
          <div>{{item.content}}</div>
        </div>
      </div>
    </div>

    <div class="footer">
      <img src="../assets/img/daodu/dibu.png" alt="">
      <div>
        <h2>选择我们的六大理由</h2>
        <div class="reason">
          <div>
            <span>授课专家</span>
            <div>甄选专业师资授课 继续教育经验丰富</div>
          </div>
          <div>
            <span>教研团队</span>
            <div>科学模块化分工 自主研发专业课程体系</div>
          </div>
          <div>
            <span>授课方法</span>
            <div>多方位教学模式 随堂练习备考更灵活</div>
          </div>
          <div>
            <span>培训经验</span>
            <div>数年继续教育经验 专业教学服务指导</div>
          </div>
          <div>
            <span>课件视频</span>
            <div>高清视频课程 课堂场景内容丰富</div>
          </div>
          <div>
            <span>遴选机构</span>
            <div>广东省药监局遴选继续教育施教机构</div>
          </div>
        </div>
      </div>
    </div>

    <div class="links round bj-col-white">
      <h2>友情链接</h2>
      <div>
        <a href="javascript:void(0);" v-for="(item, index) in links.yqlj" :key="index" @click="handleLink(item.src)">{{item.name}}</a>
      </div>
    </div>

    <!-- <img src="../assets/img/daodu.png" class="daodu" alt="" @click="$router.push({name: 'Daodu'})"> -->

    <el-dialog
      :visible.sync="show"
      width="30%"
      top="15vh"
      title="广东省执业药师继续教育改革告知"
      :close-on-click-modal="false"
      :show-close="false"
      center>
      <div class="hint">
        <p>
          1、<span>2021年</span>起继续教育管理机构：由<span>“广东省药品监督管理局事务中心”</span>统一管理；
          <br/>
          2、广东省局遴选三家网授施教机构：<span>湘源医药食品职业培训学校</span>、广东药师协会、直属医药职业技能培训指导中心，学员可自由选择任一家施教机构学习，在省局<span>“执业药师管理系统”</span>均可查询学分。
        </p>
      </div>
      <span slot="footer">
        <el-button round type="success" class="self-button--success" size="medium" style="width: 160px" @click="getUserInfo">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Api from '@/api/home.js'
import MApi from '@/api/my.js'

// import ConsultView from  './components/main/consult.vue'
import NewsView from  './components/main/news.vue'

const buzou = require('../assets/img/buzou.png')

export default {
  components: {
    // ConsultView
    NewsView
  },
  data() {
    return {
      study_step: [
        {
          title: '报名注册',
          style: {
            'backgroundImage': "url("+buzou+")",
            'backgroundSize': 'auto',
            'background-position': '-3px -6px'
          }
        },
        // {
        //   title: '委托书',
        //   style: {
        //     'backgroundImage': "url("+buzou+")",
        //     'backgroundSize': 'auto',
        //     'background-position': '-156px -6px'
        //   }
        // },
        {
          title: '选课缴费',
          style: {
            'backgroundImage': "url("+buzou+")",
            'backgroundSize': 'auto',
            'background-position': '-309px -6px'
          }
        },
        {
          title: '学习考试',
          style: {
            'backgroundImage': "url("+buzou+")",
            'backgroundSize': 'auto',
            'background-position': '-462px -6px'
          }
        },
        {
          title: '药师审核',
          style: {
            'backgroundImage': "url("+buzou+")",
            'backgroundSize': 'auto',
            'background-position': '-612px -6px'
          }
        },
        {
          title: '学分打印',
          style: {
            'backgroundImage': "url("+buzou+")",
            'backgroundSize': 'auto',
            'background-position': '-765px -6px'
          }
        },
      ],
      teacher_arr: [],
      new_arr: [],
      teacherArr: [],
      tixiArr: [],
      teacherIdx: 0,
      videoShow: false,
      consult: true,
      links: {},
      show: false
    };
  },
  computed: {},
  watch: {},
  created() {
    if (window.location.search && window.location.search.indexOf('card') != -1 && window.location.search.indexOf("id") != -1) {
      let search = window.location.search.split('&')
      search = search.map(item => {
        return item.split('=')[1]
      })
      search = search.map(item => {
        if (item.indexOf('/') != -1) {
          return item.split('/')[0]
        }
        return item
      })
      this.defLogin(search[0], search[1])
    }
    this.getTeacherArr()
    this.getTeacherList()
    this.getTixi()
    this.getLinks()
  },
  mounted() {
    
  },
  beforeDestroy () {
    clearInterval(this.homeNews)
  },
  //方法集合
  methods: {
    defLogin (card, id) {
      Api.defLogin({
        card: card,
        id: id
      }).then(res => {
        if (res.data.code == 1) {
          localStorage.setItem('year', res.data.data.year)
          localStorage.setItem('token', res.data.data.token)
          this.$store.commit('setToken', res.data.data.token)
          this.show = true
        }
      })
    },
    getUserInfo () {
      MApi.getUserInfo().then(res => {
        if (res.status == 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          localStorage.setItem('card', res.data.card)
          if (res.data.xuanke == 1) {
            this.$router.push({name: 'Xuanke'})
          } else {
            this.$router.push({name: 'Gouke'})
          }
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
    getTeacherArr () {
      Api.getTeacherArr({
        type: 1
      }).then(res => {
        if (res.data.code == 1) {
          this.teacherArr = res.data.data
        } else {
          this.$message.error("名师列表获取失败")
        }
      })
    },
    getTixi () {
      Api.getTixi().then(res => {
        if (res.data.code == 1) {
          this.tixiArr = res.data.data
        } else {
          this.$message.error("课程体系获取失败")
        }
      })
    },
    getTeacherList () {
      Api.getTeacherArr().then(res => {
        if (res.data.code == 1) {
          // this.teacher_arr = res.data.data.splice(0, 5)
          this.teacher_arr = res.data.data
          for (let i in this.teacher_arr) {
            if (this.teacher_arr[i].content.length > 28) {
              this.teacher_arr[i].content = this.teacher_arr[i].content.slice(0, 28) + '...'
            }
          }
          this.newsScroll()
        } else {
          this.$message.error("名师信息获取失败")
        }
      })
    },
    getLinks () {
      Api.getLinks().then(res => {
        this.links = res.data.data
        localStorage.setItem("czsc", this.links.czsc)
        this.study_step.forEach((item, index) => {
          item.src = this.links.xxbz[index]
        })
      })
    },
    // 自动滚动
    newsScroll () {
      this.$nextTick(() => {
        // 获取需要滚动的距离（加50是为了在尾部时多停留一段时间）
        let startX = this.$refs.homeNews.scrollWidth-this.$refs.homeNews.clientWidth+50
        // 初始滚动用来触发监听事件
        let scrollLeft = this.$refs.homeNews.scrollLeft
        this.homeNews = setInterval(() => {
          scrollLeft++
          this.$refs.homeNews.scrollLeft = scrollLeft
        }, 30)
        this.$refs.homeNews.onscroll = () => {
          // 触发滚动后清除触发定时器
          clearInterval(this.homeNews)
          // 获取滚动条位置（主要为了手动拖动时的位置）
          if (this.$refs.homeNews) {
            let offsetX = this.$refs.homeNews.scrollLeft
            // 触发滚动就改变滚动条位置，达到自动滚动效果
            if (offsetX > startX) {
              offsetX = 0
            } else {
              offsetX += 1
            }
            this.$refs.homeNews.scrollLeft = offsetX
            scrollLeft = offsetX
            this.homeNews = setInterval(() => {
              scrollLeft++
              this.$refs.homeNews.scrollLeft = scrollLeft
            }, 30)
          }
        }
      })
    },
    handleLink (url) {
      if (!url) return;
      if (url == 'zykm' || url == 'gxkm') {
        let token = localStorage.getItem('token') || ''
        if (!token) {
          this.$message.warning('登录后方可学习')
        }
        this.$router.push({name: url == 'zykm' ? 'Xuanke' : 'Gongxu'})
        return
      }
      if (url == 'xfcx') {
        this.$router.push('/xfList')
        return
      }
      window.open(url)
    },
    changeHide () {
      this.consult = false
    },
    handleMore (name) {
      this.$router.push({name: name})
    },
    handleTeacher (id) {
      this.$router.push({name: 'Teacher', query: {id: id}})
    }
  },
}
</script>

<style lang='less' scoped>
.main {
  .nav {
    display: flex;
    justify-content: space-between;
    .introduce {
      width: 30%;
      background-color: #F5F5F5;
      > div {
        padding: 20px;
        > div {
          width: 95%;
          margin: 20px auto 20px;
          display: flex;
          justify-content: space-between;
          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            span {
              color: #333333;
              font-size: 14px;
            }
            div {
              width: 70px;
              height: 70px;
              margin-bottom: 10px;
              border-radius: 50%;
            }
          }
        }
      }
      .practice {
        padding: 10px 40px;
        margin-left: 20px;
        > div {
          display: flex;
          flex-direction: column;
        }
        img {
          width: 100%;
          height: 100px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .flow { 
    padding: 20px 40px;
    margin-top: 20px;
    > div {
      width: 95%;
      margin: 40px auto 20px;
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        span {
          color: #333333;
          font-size: 14px;
        }
        div {
          width: 70px;
          height: 70px;
          margin-bottom: 10px;
          border-radius: 50%;
        }
      }
    }
  }

  .flows {
    background-color: #fff;
    padding: 20px 40px;
    margin-top: 20px;
    > div {
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        text-align: center;
        cursor: pointer;
        .xuhao {
          width: 60px;
          height: 60px;
          line-height: 60px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #000;
          font-size: 20px;
          font-weight: bold;
        }
        .line {
          background-color: #000;
          width: 160px;
          height: 13px;
          margin: 10px auto 15px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: -20px;
            border-width: 20px 20px;
            border-style: solid;
            border-color: #fff transparent transparent transparent;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            right: -20px;
            border-width: 20px 20px;
            border-style: solid;
            border-color: transparent transparent  #fff transparent;
          }
        }
      }
    }
  }

  .continue {
    padding: 10px 40px 20px;
    margin-top: 10px;
    > div {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      > div {
        flex: 1;
        margin-right: 15px;
        background-color: #f0f0f0;
        border-radius: 20px;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        > span {
          width: 80%;
          margin: 0 auto;
          display: block;
          text-align: center;
          white-space: pre-wrap;
          word-break: break-all;
          font-size: 20px;
          font-weight: bold;
          color: #494949;
          letter-spacing: 3px;
        }
        img {
          width: 100%;
          margin: 15px 0;
        }
        div {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-radius: 40px;
          color: #fff;
          background-color: #06B487;
          text-align: center;
          letter-spacing: 3px;
          font-size: 20px;
          cursor: pointer;
        }
        p {
          width: 80%;
          margin: 0 auto;
          display: block;
          text-align: center;
          white-space: pre-wrap;
          word-break: break-all;
          font-size: 18px;
          color: #09B38A;
          letter-spacing: 3px;
          span {
            font-size: 18px;
            color: red;
          }
        }
      }
      > div:last-child {
        margin-right: 0;
      }
      .upload {
        cursor: pointer;
      }
    }
  }

  .new-flows {
    padding: 20px 40px;
    margin-top: 20px;
    background-color: #F5F5F5;
    > div {
      margin: 50px 0;
      width: 100%;
      background-image: url('../assets/img/flows.png');
      background-size: 100% 80%;
      display: flex;
      justify-content: space-between;
      height: 150px;
      overflow: visible;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        > span {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          line-height: 50px;
          background-color: #fff;
          color: #09B38A;
          font-size: 22px;
          text-align: center;
          font-weight: bold;
        }
        > div {
          font-size: 22px;
          text-align: center;
          background-color: #ffffff;
          color: #09B38A;
          padding: 10px 10px;
          font-weight: bold;
          margin: 15px 0;
          min-width: 160px;
          border-radius: 5px;
          position: relative;
          min-height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            border: 5px solid #fff;
          }
        }
        .bottom {
          &::after {
            top: auto;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
          }
        }
        &:hover {
          > span {
            background-color: #09B38A;
            color: #fff;
          }
          > div {
            background-color: #09B38A;
            color: #fff;
            &::after {
              border: 5px solid #09B38A;
            }
          }
        }
      }
    }
  }

  .news {
    padding: 20px 40px;
    margin-top: 40px;
    position: relative;
    .consult {
      width: 100%;
    }
  }

  .teacher-list {
    padding: 20px 40px;
    margin-top: 20px;
    .msg {
      margin: 50px auto;
      display: flex;
      .head {
        width: 40%;
        height: 280px;
        border-radius: 10px;
        background-color: #6ED5AC;
        display: flex;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        img {
          width: 180px;
          height: 240px;
        }
        > div {
          width: 220px;
          height: 50px;
          border-radius: 25px;
          padding: 0 15px;
          background-color: #DCFFF0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #016B4D;
            font-size: 22px;
            margin-left: 20px;
          }
          > div {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #016B4D;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            > div {
              border: 12px solid #016B4D;
              border-top-width: 8px;
              border-bottom-width: 8px;
              border-left-color: #ffffff;
              margin-left: 14px;
            }
          }
        }
      }
      video {
        width: 40%;
        height: 280px;
        background-color: #000;
      }
      .content {
        flex: 1;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
          display: flex;
          justify-content: space-between;
          span {
            font-size: 20px;
          }
        }
        .detail {
          white-space: pre-wrap;
          word-break: break-all;
          flex: 1;
          margin: 20px 0;
        }
      }
    }
    .arr {
      padding: 20px 0;
      overflow-y: visible;
      overflow-x: auto;
      display: flex;
      > div {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        > .head {
          width: 100px;
          height: 100px;
          position: relative;
          border-radius: 50%;
          box-sizing: content-box;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name {
          box-sizing: content-box;
          margin: 20px 0 0;
          padding: 2px 5px;
        }
      }
      > div:last-child {
        margin-right: 50px;
      }
      .checked {
        .head {
          border: 1px solid #4CC08C;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 50%;
            transform: translate(8px, -100%);
            border-width: 20px 8px;
            border-style: solid;
            border-color: transparent transparent #4CC08C transparent;
          }
        }
        .name {
          border: 1px solid #4CC08C;
          color: #4CC08B;
        }
      }
    }
  }

  .tixi {
    margin: 50px 0;
    padding: 20px 40px;
    .table {
      margin-top: 30px;
      border: 1px solid #06B487;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      .tlist {
        max-height: 540px;
        overflow-y: auto;
      }
      .tbody,
      .thead {
        display: flex;
        > div {
          padding: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .xuhao {
          width: 6%;
        }
        .name {
          width: 33%;
        }
        .user {
          width: 20%;
        }
        .title {
          width: 40%;
        }
      }
      .thead {
        background-color: #06B487;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        color: #ffffff;
        margin-bottom: 1px;
        div {
          font-size: 20px;
        }
      }
      .tbody {
        > div {
          border-bottom: 1px solid #D8D8D8;
        }
        > .xuhao {
          border: 0;
          background-color: #4B4F62;
          color: #ffffff;
        }
        .name, .user {
          border-right: 1px solid #D8D8D8;
        }
      }
    }
  }

  .footer {
    position: relative;
    img {
      width: 100%;
      height: 300px;
    }
    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      > div {
        width: 90%;
      }
      h3 {
        text-align: center;
      }
      .reason {
        display: flex;
        justify-content: space-between;
        > div {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          border: 1px solid #4CC08D;
          box-shadow: 0px 2px 4px 0px #4CC08D;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          div {
            width: 75%;
            margin-top: 20px;
            text-align: center;
          }
          span {
            font-size: 20px;
            color: #4CC08C;
          }
        }
      }
    }
  }

  .course {
    padding: 20px 40px;
    margin: 20px auto;
    position: relative;
    img {
      max-width: 100%;
      display: block;
      margin: 20px auto 0;
      cursor: pointer;
    }
  }
  .more {
    position: absolute;
    top: 22px;
    right: 40px;
    font-size: 14px;
    color: #999999;
    cursor: pointer;
  }
  .teacher {
    padding: 20px 40px;
    position: relative;
    margin-bottom: 20px;
    .teacher-arr {
      width: 95%;
      overflow-x: auto;
      margin: 0 auto;
      display: flex;
      padding: 0 0 20px;
      > div {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 60px;
        cursor: pointer;
        img {
          width: 240px;
          height: 280px;
        }
        > div {
          padding: 0 20px;
          text-align: center;
        }
        span {
          padding-bottom: 5px;
          border-bottom: 2px solid @default;
          margin: 10px 0 5px;
        }
      }
      > div:last-child {
        margin-right: 0;
      }
    }
  }
  .consult {
    position: relative;
    padding: 20px 40px;
    margin: 0 30px 0 0;
    flex: 1;
    .consult {
      width: 100%;
      padding: 20px 20px;
    }
  }
  .links {
    padding: 20px 40px;
    margin: 20px 0;
    > div {
      padding: 40px 0 30px;
      display: flex;
      a {
        color: @link;
        margin: 0 30px;
      }
    }
  }

  .daodu {
    position: fixed;
    right: 8%;
    top: 70%;
    width: 150px;
    height: 150px;
    cursor: pointer;
    border-radius: 50%;
    transform: translate(50%, 0);
  }

  .hint {
    span {
      color: red;
      font-weight: bold;
    }
  }
}
</style>